/*---- entity-list ----------- */
.entity-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  position: relative;
}

.entity-list_header {
  font-size: 23px;
}

.entity-list_title {
  font-size: 16px;
}

.entity-list_description {
  font-size: 13px;
  padding-bottom: 25px;
}

.entity-list_top {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.entity-list_bottom {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.entity-list_items {
  width: 100%;
  overflow-y: auto;
  max-height: 550px;
}

.entity-list_btn-downloadallfinal {
  align-self: flex-end;
}

.entity-list_btn-downloadall {
  align-self: flex-end;
  font-size: 11px;
  height: auto;
}

.entity-list_space {
  flex-grow: 1;
}

.entity-list > div {
  margin: 10px 0 10px 0;
}
.entity-list > div:first-child {
  margin-top: 0;
}
.entity-list > div:last-child {
  margin-bottom: 0;
}

.entity-list_loader {
  width: 100%;
  z-index: 100;
  height: 100%;
  position: absolute;
  display: flex;
  background-color: #ffffffcc;
}
