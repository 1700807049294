.ap-modal-content {
  max-width: 1024px !important;
}

.help-guide {
  max-width: 1024px;
  max-height: 680px;
  padding: 0 8px 32px;
  margin: 0 auto;
}
.help-guide p {
  margin: 16px 0 0 0;
}
.help-guide p.final {
  padding-bottom: 32px;
}
.help-guide img {
  margin: 8px 0 0;
  max-width: 100%;
}
