/* .error-message {
  display: flex;
  flex-direction: column;
} */

.error-message_title {
  font-size: 18px;
}

.error-message_text {
  color: crimson;
  white-space: pre-wrap;
  max-height: 470px;
  overflow-y: scroll;
  padding-top: 8px;
}
