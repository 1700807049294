.icon {
    display: flex;
    justify-content: center;
}

.icon svg {
    align-self: center;
}

.icon-image g {
    transition: fill 0.1s ease-out;
}

.icon.flip-vertically svg {
    transform: scale(-1);
}

.icon.rotate90 svg,
.icon.rotate90 i {
    transform: rotate(90deg);
}

.icon.rotate180 svg {
    transform: rotate(180deg);
}

.icon-material {
    font-size: inherit;
}
