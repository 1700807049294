.entity-listitem {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #999999;
  padding: 5px 0 5px 0;
}

.entity-shell {
  background-color: #f2f2f2;
}

.entity-listitem:last-child {
  border-bottom: 1px solid #999999;
}

.entity-listitem_name {
  color: #488929;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.entity-listitem_validation {
  font-size: 12px;
  margin-left: 10px;
}

.entity-listitem_space {
  flex-grow: 1;
}

.entity-listitem_loadinglabel {
  font-size: 12px;
  margin-right: 10px;
}

.entity-listitem_download {
  display: flex;
  width: 36px;
  height: 36px;
}

.entity-listitem_shell {
  margin-left: 10px;
}
