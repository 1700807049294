td.missing-cell {
  color: #8d0e0c;
  background-color: var(--pink1);
}

.check-table {
  width: 900px;
  min-width: 800px;
  padding-bottom: 4px;
}

.check-table_error-text {
  font-style: italic;
  font-size: 11px;
  margin: 6px 0px 6px 0px;
}
