.nav-bar {
  position: fixed;
}

.homepage-navbar a {
  text-decoration: none;
  color: inherit;
  background-color: transparent;
}

.homepage-navbar__list {
  display: none;
  position: relative;
}
.homepage-navbar__item.active .menu-dropdown__label {
  color: #ffffff;
}

.homepage-navbar__item.active .menu-dropdown__label:hover {
  color: var(--ap-green-1);
  cursor: pointer;
}
.homepage-navbar__item.active
  .menu-dropdown__label:hover
  + .menu-dropdown__icon
  svg {
  color: var(--ap-green-1);
}
.homepage-navbar__item.active .menu-dropdown__icon svg:hover {
  fill: var(--ap-green-1);
}

.homepage-navbar__item {
  display: inline-flex;
  align-items: center;
  color: #999999;
  user-select: none;
  margin-right: 24px;
}
.homepage-navbar__item:first-child {
  padding-right: 8px;
}
.homepage-navbar__item:last-child {
  margin-right: 0;
}

.menu-dropdown__label,
.homepage-navbar__link {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  outline: 0;
  display: inline-flex;
  align-items: center;
  transition: color cubic-bezier(0.19, 1, 0.22, 1);
  color: inherit;
  text-decoration: none;
}

.homepage-navbar__link:hover {
  color: var(--ap-green-1);
}

.homepage-navbar__link:focus,
.homepage-navbar__link.active {
  color: white;
}

.homepage-navbar__link + .homepage-navbar__link {
  margin-left: 16px;
  margin-right: 24px;
}

/* Make left part narrow */
.homepage-navbar > div > div:first-child {
  max-width: 150px;
}

.homepage-navbar__nav-link {
  display: flex;
  align-self: stretch;
  align-items: center;
}

.homepage-navbar__item.my-engagements {
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin-right: 32px;
}

@media (min-width: 960px) {
  .homepage-navbar__list {
    display: inline-flex;
  }
}
