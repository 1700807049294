#homepage {
  width: 100vw;
  min-height: calc(100vh - 48px);
  padding: 32px 0;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.split-content {
  display: flex;
  justify-content: space-between;
}

.table-container {
  margin-top: 8px;
}

.validation-result {
  background-color: var(--grey1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
  padding: 16px;
}
.validation-result p {
  font-style: italic;
}

.box-container {
  min-height: 200px;
  min-width: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

/* Upload page */
div.upload-top-area {
  text-align: left;
  width: 940px;
}
div.upload-top-area p {
  margin-top: 16px;
}

div.upload-excel_templates {
  text-align: left;
  margin-top: 16px;
}
div.upload-excel_templates a:hover {
  opacity: 0.7;
}

.upload-hr {
  margin: 16px 0 0;
}

div.upload-bottom-area {
  margin-top: 32px;
  text-align: left;
}
div.upload-bottom-area p {
  margin: 16px 0 16px;
}