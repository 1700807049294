.page-homepage {
  width: 100vw;
  min-height: calc(100vh - 48px);
  padding: 32px 0;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-entities {
  margin-top: 26px;
}

.split-content {
  display: flex;
  justify-content: space-between;
}

.table-container {
  margin-top: 8px;
}

.validation-result {
  background-color: var(--grey1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
  padding: 16px;
}
.validation-result p {
  font-style: italic;
  cursor: pointer;
}

.validation-dialog {
  white-space: pre-wrap;
}

.upload-entities_loadingText {
  text-align: center;
}

.processing-box {
  border: 1px dashed #999;
  background-color: #f7f7f7;
  padding: 20px 20px 30px 20px;
}

.upload-complete_item {
  margin: 8px;
}
