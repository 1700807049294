@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;900&display=swap');

:root {
  --ap-green-1: #5cb335;
  --grey1: #f7f7f7;
  --grey2: #e6e6e6;
  --grey3: #cccccc;
  --grey4: #999999;
  --pink1: #eddcdc;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', Noto Sans SC, Noto Sans JP, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

h1 {
  font-size: 2.5rem;
  font-weight: 900;
  margin: 0;
  margin-bottom: 32px;
  letter-spacing: 0.3rem;
}

h2 {
  font-size: 24px;
  font-weight: 900;
  color: #383838;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: -0.02em;
}

h3 {
  font-size: 32px;
  font-weight: 500;
  color: #383838;
  line-height: 32px;
  text-transform: none;
  letter-spacing: -0.02em;
  margin: 0;
  padding: 0;
}

h4 {
  font-size: 28px;
  font-weight: 500;
  color: #383838;
  line-height: 28px;
  text-transform: none;
  letter-spacing: -0.02em;
  margin: 0;
  padding: 0;
}

h5 {
  font-size: 24px;
  font-weight: 400;
  color: #383838;
  line-height: 24px;
  text-transform: none;
  letter-spacing: -0.02em;
  margin: 0;
  padding: 0;
}

p {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.015em;
  line-height: 22px;
  color: #333;
  margin: 0;
  padding: 0;
}

strong {
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mb-16 {
  margin-bottom: 16px;
}
.mb-32 {
  margin-bottom: 32px;
}
