.progress-bar_body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.progress-bar_title {
  margin-bottom: 16px;
}

.progress-bar_container {
  width: 100%;
  background-color: #e0e0de;
  border-radius: 50;
}

.progress-bar_filler {
  height: 100%;
  border-radius: inherit;
}
