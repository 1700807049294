@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;900&display=swap);
:root {
  --ap-green-1: #5cb335;
  --grey1: #f7f7f7;
  --grey2: #e6e6e6;
  --grey3: #cccccc;
  --grey4: #999999;
  --pink1: #eddcdc;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', Noto Sans SC, Noto Sans JP, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

h1 {
  font-size: 2.5rem;
  font-weight: 900;
  margin: 0;
  margin-bottom: 32px;
  letter-spacing: 0.3rem;
}

h2 {
  font-size: 24px;
  font-weight: 900;
  color: #383838;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: -0.02em;
}

h3 {
  font-size: 32px;
  font-weight: 500;
  color: #383838;
  line-height: 32px;
  text-transform: none;
  letter-spacing: -0.02em;
  margin: 0;
  padding: 0;
}

h4 {
  font-size: 28px;
  font-weight: 500;
  color: #383838;
  line-height: 28px;
  text-transform: none;
  letter-spacing: -0.02em;
  margin: 0;
  padding: 0;
}

h5 {
  font-size: 24px;
  font-weight: 400;
  color: #383838;
  line-height: 24px;
  text-transform: none;
  letter-spacing: -0.02em;
  margin: 0;
  padding: 0;
}

p {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.015em;
  line-height: 22px;
  color: #333;
  margin: 0;
  padding: 0;
}

strong {
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mb-16 {
  margin-bottom: 16px;
}
.mb-32 {
  margin-bottom: 32px;
}

.nav-bar {
  position: fixed;
}

.homepage-navbar a {
  text-decoration: none;
  color: inherit;
  background-color: transparent;
}

.homepage-navbar__list {
  display: none;
  position: relative;
}
.homepage-navbar__item.active .menu-dropdown__label {
  color: #ffffff;
}

.homepage-navbar__item.active .menu-dropdown__label:hover {
  color: var(--ap-green-1);
  cursor: pointer;
}
.homepage-navbar__item.active
  .menu-dropdown__label:hover
  + .menu-dropdown__icon
  svg {
  color: var(--ap-green-1);
}
.homepage-navbar__item.active .menu-dropdown__icon svg:hover {
  fill: var(--ap-green-1);
}

.homepage-navbar__item {
  display: inline-flex;
  align-items: center;
  color: #999999;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin-right: 24px;
}
.homepage-navbar__item:first-child {
  padding-right: 8px;
}
.homepage-navbar__item:last-child {
  margin-right: 0;
}

.menu-dropdown__label,
.homepage-navbar__link {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  outline: 0;
  display: inline-flex;
  align-items: center;
  transition: color cubic-bezier(0.19, 1, 0.22, 1);
  color: inherit;
  text-decoration: none;
}

.homepage-navbar__link:hover {
  color: var(--ap-green-1);
}

.homepage-navbar__link:focus,
.homepage-navbar__link.active {
  color: white;
}

.homepage-navbar__link + .homepage-navbar__link {
  margin-left: 16px;
  margin-right: 24px;
}

/* Make left part narrow */
.homepage-navbar > div > div:first-child {
  max-width: 150px;
}

.homepage-navbar__nav-link {
  display: flex;
  align-self: stretch;
  align-items: center;
}

.homepage-navbar__item.my-engagements {
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  margin-right: 32px;
}

@media (min-width: 960px) {
  .homepage-navbar__list {
    display: inline-flex;
  }
}

.ap-modal-content {
  max-width: 1024px !important;
}

.help-guide {
  max-width: 1024px;
  max-height: 680px;
  padding: 0 8px 32px;
  margin: 0 auto;
}
.help-guide p {
  margin: 16px 0 0 0;
}
.help-guide p.final {
  padding-bottom: 32px;
}
.help-guide img {
  margin: 8px 0 0;
  max-width: 100%;
}

.box {
  transition: all 1.3s ease-in-out;
  position: relative;
  border: 1px solid var(--grey3);
  background-color: white;
  padding: 32px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dragging .upload-form {
  opacity: 0.8;
}

.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 320px;
  position: relative;
  transition: min-height 1.3s ease-in-out;
  border: 1px dashed #999;
  padding: 32px;
  background-color: #FEFEFE;
}
.upload-form.shrink {
  min-height: 10%;
  margin: 32px auto;
}

div.upload-form p {
  margin: 0;
}

.upload-form .upload-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 640px;
}

.upload-form.active .upload-wrapper {
  border: 1px dashed var(--grey4);
}

.upload-form .upload-tile-drag-text {
  margin-bottom: 8px;
}

.upload-form .button-container {
  margin-top: 24px;
}

.upload-form .upload-icon {
  height: 100px;
}
.box-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.box-section_title {
  font-size: 28px;
  font-weight: 500;
  color: #383838;
  line-height: 28px;
  text-transform: none;
  letter-spacing: -0.02em;
}

.box-section_description {
  font-size: 16px;
  letter-spacing: 0.015em;
  line-height: 22px;
  color: #333;
  margin-top: 36px;
}

.box-section_body {
  margin-top: 16px;
  width: 100%;
}

.box-section_bottom {
  margin-top: 32px;
  width: 100%;
}

.icon {
    display: flex;
    justify-content: center;
}

.icon svg {
    align-self: center;
}

.icon-image g {
    transition: fill 0.1s ease-out;
}

.icon.flip-vertically svg {
    transform: scale(-1);
}

.icon.rotate90 svg,
.icon.rotate90 i {
    transform: rotate(90deg);
}

.icon.rotate180 svg {
    transform: rotate(180deg);
}

.icon-material {
    font-size: inherit;
}

.entity-listitem {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #999999;
  padding: 5px 0 5px 0;
}

.entity-shell {
  background-color: #f2f2f2;
}

.entity-listitem:last-child {
  border-bottom: 1px solid #999999;
}

.entity-listitem_name {
  color: #488929;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
}

.entity-listitem_validation {
  font-size: 12px;
  margin-left: 10px;
}

.entity-listitem_space {
  flex-grow: 1;
}

.entity-listitem_loadinglabel {
  font-size: 12px;
  margin-right: 10px;
}

.entity-listitem_download {
  display: flex;
  width: 36px;
  height: 36px;
}

.entity-listitem_shell {
  margin-left: 10px;
}

/*---- entity-list ----------- */
.entity-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  position: relative;
}

.entity-list_header {
  font-size: 23px;
}

.entity-list_title {
  font-size: 16px;
}

.entity-list_description {
  font-size: 13px;
  padding-bottom: 25px;
}

.entity-list_top {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.entity-list_bottom {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.entity-list_items {
  width: 100%;
  overflow-y: auto;
  max-height: 550px;
}

.entity-list_btn-downloadallfinal {
  align-self: flex-end;
}

.entity-list_btn-downloadall {
  align-self: flex-end;
  font-size: 11px;
  height: auto;
}

.entity-list_space {
  flex-grow: 1;
}

.entity-list > div {
  margin: 10px 0 10px 0;
}
.entity-list > div:first-child {
  margin-top: 0;
}
.entity-list > div:last-child {
  margin-bottom: 0;
}

.entity-list_loader {
  width: 100%;
  z-index: 100;
  height: 100%;
  position: absolute;
  display: flex;
  background-color: #ffffffcc;
}

/* .error-message {
  display: flex;
  flex-direction: column;
} */

.error-message_title {
  font-size: 18px;
}

.error-message_text {
  color: crimson;
  white-space: pre-wrap;
  max-height: 470px;
  overflow-y: scroll;
  padding-top: 8px;
}

td.missing-cell {
  color: #8d0e0c;
  background-color: var(--pink1);
}

.check-table {
  width: 900px;
  min-width: 800px;
  padding-bottom: 4px;
}

.check-table_error-text {
  font-style: italic;
  font-size: 11px;
  margin: 6px 0px 6px 0px;
}

.progress-bar_body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.progress-bar_title {
  margin-bottom: 16px;
}

.progress-bar_container {
  width: 100%;
  background-color: #e0e0de;
  border-radius: 50;
}

.progress-bar_filler {
  height: 100%;
  border-radius: inherit;
}

.page-homepage {
  width: 100vw;
  min-height: calc(100vh - 48px);
  padding: 32px 0;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-entities {
  margin-top: 26px;
}

.split-content {
  display: flex;
  justify-content: space-between;
}

.table-container {
  margin-top: 8px;
}

.validation-result {
  background-color: var(--grey1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
  padding: 16px;
}
.validation-result p {
  font-style: italic;
  cursor: pointer;
}

.validation-dialog {
  white-space: pre-wrap;
}

.upload-entities_loadingText {
  text-align: center;
}

.processing-box {
  border: 1px dashed #999;
  background-color: #f7f7f7;
  padding: 20px 20px 30px 20px;
}

.upload-complete_item {
  margin: 8px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.no-select {
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.message-dialog {
  padding: 0 90px 0 90px;
  min-width: 700px;
}

/* Fix for navigation bar */
.App > div:first-child > div:first-child > a:first-child,
.App > div:first-child > div:first-child > div:empty  {
  display: none;
}

#homepage {
  width: 100vw;
  min-height: calc(100vh - 48px);
  padding: 32px 0;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.split-content {
  display: flex;
  justify-content: space-between;
}

.table-container {
  margin-top: 8px;
}

.validation-result {
  background-color: var(--grey1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
  padding: 16px;
}
.validation-result p {
  font-style: italic;
}

.box-container {
  min-height: 200px;
  min-width: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

/* Upload page */
div.upload-top-area {
  text-align: left;
  width: 940px;
}
div.upload-top-area p {
  margin-top: 16px;
}

div.upload-excel_templates {
  text-align: left;
  margin-top: 16px;
}
div.upload-excel_templates a:hover {
  opacity: 0.7;
}

.upload-hr {
  margin: 16px 0 0;
}

div.upload-bottom-area {
  margin-top: 32px;
  text-align: left;
}
div.upload-bottom-area p {
  margin: 16px 0 16px;
}
