.box-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.box-section_title {
  font-size: 28px;
  font-weight: 500;
  color: #383838;
  line-height: 28px;
  text-transform: none;
  letter-spacing: -0.02em;
}

.box-section_description {
  font-size: 16px;
  letter-spacing: 0.015em;
  line-height: 22px;
  color: #333;
  margin-top: 36px;
}

.box-section_body {
  margin-top: 16px;
  width: 100%;
}

.box-section_bottom {
  margin-top: 32px;
  width: 100%;
}
