.dragging .upload-form {
  opacity: 0.8;
}

.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 320px;
  position: relative;
  transition: min-height 1.3s ease-in-out;
  border: 1px dashed #999;
  padding: 32px;
  background-color: #FEFEFE;
}
.upload-form.shrink {
  min-height: 10%;
  margin: 32px auto;
}

div.upload-form p {
  margin: 0;
}

.upload-form .upload-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 640px;
}

.upload-form.active .upload-wrapper {
  border: 1px dashed var(--grey4);
}

.upload-form .upload-tile-drag-text {
  margin-bottom: 8px;
}

.upload-form .button-container {
  margin-top: 24px;
}

.upload-form .upload-icon {
  height: 100px;
}